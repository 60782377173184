import React, {FC, memo} from 'react';
import {FimboItemWithChange} from "../../FimboItemPage";
import FimboItemListenMp3Button from "./FimboItemListenMp3Button";

const FimboItemInfoRight: FC<FimboItemWithChange> = memo(({fimbo, wasChanges}) => {


    return (
        <>
            {fimbo.notes.size22.map(note =>
                <div key={note.note}>
                    <div className="FimboItemPage__form__item" key={note.note}>
                        <div className="FimboItemPage__form__item_text">22см нота {note.note}</div>
                        <input autoComplete={"off"} required name={"fimnote22cm_" + note.note}
                               onChange={() => {
                                   wasChanges.current = true
                               }} defaultValue={note.file_sound}
                               className="FimboItemPage__form__item_input"/>
                    </div>
                    <div className="hint__description__field">Название аудио файла ноты №{note.note} для 22см Фимбо.
                        (Например note_22_{note.note}.mp4)
                    </div>
                </div>
            )}
            {fimbo.notes.size27.map(note =>
                <div key={note.note}>
                    <div className="FimboItemPage__form__item" key={note.note}>
                        <div className="FimboItemPage__form__item_text">27см нота {note.note}</div>
                        <input autoComplete={"off"} required name={"fimnote27cm_" + note.note}
                               onChange={() => {
                                   wasChanges.current = true
                               }} defaultValue={note.file_sound}
                               className="FimboItemPage__form__item_input"/>
                    </div>
                    <div className="hint__description__field">
                        Название аудио файла ноты №{note.note} для 27см Фимбо.
                        (Например note_27_{note.note}.mp4)
                    </div>
                </div>
            )}
            {fimbo.notes.size32.map(note =>
                <div key={note.note}>
                    <div className="FimboItemPage__form__item" key={note.note}>
                        <div className="FimboItemPage__form__item_text">32см нота {note.note}</div>
                        <input autoComplete={"off"} required name={"fimnote32cm_" + note.note}
                               onChange={() => {
                                   wasChanges.current = true
                               }} defaultValue={note.file_sound}
                               className="FimboItemPage__form__item_input"/>
                    </div>
                    <div className="hint__description__field">Название аудио файла ноты №{note.note} для 32см Фимбо.
                        (Например note_32_{note.note}.mp4)
                    </div>
                </div>
            )}

            <FimboItemListenMp3Button fimbo={fimbo}/>

            {fimbo.lepest.map(lepest =>
                <div className="FimboItemPage__form__lepest__item" key={lepest.lep}>
                    <div className="FimboItemPage__form__item_text">Лепесток {lepest.lep}</div>
                    <div className="FimboItemPage__form__lepest__item__row">
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>rotate</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_transform"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.transform} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>width</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_width"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.width} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>height</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_height"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.height} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>left</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_left"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.left} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>top</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_top"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.top} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>num top</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_num_top"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.num_top} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                        <div className="FimboItemPage__form__lepest__item__row__item">
                            <div>num left</div>
                            <input autoComplete={"off"} required name={"lep" + lepest.lep + "_num_left"}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }} defaultValue={lepest.num_left} pattern="[0-9]+.?[0-9]*"
                                   className="FimboItemPage__form__lepest__input"/>
                        </div>
                    </div>
                </div>
            )}

            <div className="hint__description__field">Rotate - Количество градусов, на которые повернут лепесток,
                находящийся на странице плеера.
            </div>
            <div className="hint__description__field">Width - значение ширины лепестка(в процентах, знак процента
                ставить не нужно).
            </div>
            <div className="hint__description__field">Height - значение высоты лепестка(в процентах, знак процента
                ставить не нужно).
            </div>
            <div className="hint__description__field">Left - значение, на котором лепесток находится от левого края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Top - значение, на котором лепесток находится от верхнего края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Num top - значение, на котором цифра находится от левого края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Num left - значение, на котором цифра находится от верхнего края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Для того, чтобы подобрать эти значения, нужно перейти на страницу
                плеера, открыть этот Фимбо и подбивать значения стилей лепестка и цифры в режиме разработчика (rotate,
                left, right, width, height - для лепестка; num top, num left - для цифры.
            </div>

        </>
    );
})

export default FimboItemInfoRight;