import React, {useMemo} from 'react';
import "./AnalyticSoulBlock.scss"
import AnalyticSoulItemBlock from "./AnalyticSoulItemBlock";
import AnalyticSoulCircleDiagram from "./AnalyticSoulCircleDiagram";
import {AnalyticApi} from "../../../../store/services/AnalyticApiService";
import FailedFetchMessage from "../../../../components/Messages/FailedFetchMessage";
import Loading from "../../../../components/Loading/Loading";

const AnalyticSoulBlock = () => {
    const {data, isLoading, isError} = AnalyticApi.useFetchSoulLikesQuery("")

    const temperamentAnswer = useMemo(() => data ? data.soulLikes.filter(ent => (ent.block === "temperament")) : [], [data])
    const horoscopeAnswer = useMemo(() => data ? data.soulLikes.filter(ent => (ent.block === "horoscope")) : [], [data])
    const colorAnswer = useMemo(() => data ? data.soulLikes.filter(ent => (ent.block === "color")) : [], [data])


    return (
        <div className="AnalyticLikes__container">
            <div className="AnalyticLikes__header">
                Аналитика по ответам по душе
            </div>


            {isError &&
                <FailedFetchMessage/>
            }


            {(isLoading) ?
                <Loading/>
                :
                <>
                    {(data) &&
                        <>

                            <div className="SoulAnswer__header">Темперамент</div>
                            <div className="SoulAnswer__item__row">
                                <div className="SoulAnswer__item__name">Текст</div>
                                <div className="SoulAnswer__item__stat">Активные ответы</div>
                                <div className="SoulAnswer__item__stat">История ответов</div>
                            </div>
                            {temperamentAnswer!.map((ent, index) =>
                                <AnalyticSoulItemBlock key={ent.id} soulLike={ent} index={index}/>
                            )}

                            <AnalyticSoulCircleDiagram soulLikes={temperamentAnswer!}/>

                            <div className="SoulAnswer__header">Гороскоп</div>
                            <div className="SoulAnswer__item__row">
                                <div className="SoulAnswer__item__name">Текст</div>
                                <div className="SoulAnswer__item__stat">Активные ответы</div>
                                <div className="SoulAnswer__item__stat">История ответов</div>
                            </div>
                            {horoscopeAnswer!.map((ent, index) =>
                                <AnalyticSoulItemBlock key={ent.id} soulLike={ent} index={index}/>
                            )}

                            <AnalyticSoulCircleDiagram soulLikes={horoscopeAnswer!}/>


                            <div className="SoulAnswer__header">Цвета</div>
                            <div className="SoulAnswer__item__row">
                                <div className="SoulAnswer__item__name">Текст</div>
                                <div className="SoulAnswer__item__stat">Активные ответы</div>
                                <div className="SoulAnswer__item__stat">История ответов</div>
                            </div>
                            {colorAnswer!.map((ent, index) =>
                                <AnalyticSoulItemBlock key={ent.id} soulLike={ent} index={index}/>
                            )}

                            <AnalyticSoulCircleDiagram soulLikes={colorAnswer!}/>

                        </>
                    }

                </>
            }


        </div>
    );
};

export default AnalyticSoulBlock;