import React, {FC, useRef} from 'react';
import {Pie, PieChart, Tooltip} from "recharts";
import {FimboLikesAnalytic} from "../../../../models/Models";

const AnalyticFimboCircleDiagram : FC<{ fimboLikes: FimboLikesAnalytic[]}> = ({fimboLikes}) => {

    const dataActiveLikes = useRef(fimboLikes.map((ent) =>
        ({name: ent.name, value: Number(ent.total_likes_state)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const dataActiveDislikes = useRef(fimboLikes.map((ent) =>
        ({name: ent.name, value: Number(ent.total_dislikes_state)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const dataHistoryLikes = useRef(fimboLikes.map((ent) =>
        ({name: ent.name, value: Number(ent.total_likes_history)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const dataHistoryDislikes = useRef(fimboLikes.map((ent) =>
        ({name: ent.name, value: Number(ent.total_dislikes_history)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const width = useRef(300)
    const height = useRef(300)


    return (
        <div className="FimboLikes__diagram__mainContainer">
            <div className="FimboLikes__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataActiveLikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                Активные лайки
            </div>

            <div className="FimboLikes__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataActiveDislikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                Активные Дизлайки
            </div>

            <div className="FimboLikes__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataHistoryLikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                История лайков
            </div>


            <div className="FimboLikes__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataHistoryDislikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                История дизлайков
            </div>


        </div>

    );
};

export default AnalyticFimboCircleDiagram;