import React, {FC, memo} from 'react';
import {IFimboAdd} from "../../FimboAddPage";
import FimboAddImg from "./FimboAddImg";
import FimboAddPurchase from "./FimboAddPurchase";
import FimboAddVideo from "./FimboAddVideo";
import FimboAddAutotextDesign from "./FimboAddAutotextDesign";
import FimboAddAutotextSound from "./FimboAddAutotextSound";

const FimboAddInfoLeft : FC<IFimboAdd> = memo(({wasChanges}) => {
    return (
        <>
            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Название
                </div>
                <input autoComplete={"off"} required name="name"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Название Фимбо.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Позиция сортировки
                </div>
                <input autoComplete={"off"} required name="position_weight" pattern="[0-9]+" defaultValue={50}
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">
                Чем больше значение, тем первее элемент будет идти по списку.
            </div>

            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Вес приоритета
                </div>
                <input autoComplete={"off"} required name="priority_weight" pattern="[0-9]+" defaultValue={50}
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>

            <div className="hint__description__field">
                Чем больше значение, тем более приоритнее данный Фимбо в выборе Фимбо.
            </div>

            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Картинка
                </div>
                <input autoComplete={"off"} required name="img"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Название картинки. (Например fimbo.jpg, лежит внутри папки
                Фимбо /cover)
            </div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Картинка на стр "Слушать"
                </div>
                <input autoComplete={"off"} required name="img_listen"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Название картинки, которая отображается на странице "Слушать".
                (Например fimbos.jpg, лежит внутри папки Фимбо, внутри /cover)
            </div>


            <FimboAddImg wasChanges={wasChanges}/>

            <FimboAddVideo wasChanges={wasChanges}/>

            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Основной цвет фона
                </div>
                <input autoComplete={"off"} required name="background_first_color"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Цвет заднего фона, который покрывает всю страницу, за Фимбо на странице плеера.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Доп. цвет фона
                </div>
                <input autoComplete={"off"} required name="background_second_color"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Цвет заднего фона, который лежит поверх основного фона, за Фимбо на странице плеера.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Цвет лепестков
                </div>
                <input autoComplete={"off"} required name="note_default_color"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Цвет лепестков на картинке Фимбо на странице плеера.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    L цвет текста
                </div>
                <input autoComplete={"off"} required name="note_first_color"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Цвет текста нот, которые играются левой рукой.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    R цвет текста
                </div>
                <input autoComplete={"off"} required name="note_second_color"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Цвет текста нот, которые играются правой рукой.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    Путь
                </div>
                <input autoComplete={"off"} required name="path"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Название папки Фимбо.</div>


            <div className="FimboAddPage__form__item">
                <div className="FimboAddPage__form__item_text">
                    URL кнопки "Купить"
                </div>
                <input autoComplete={"off"} required name="buy_url"
                       onChange={() => {
                           wasChanges.current = true
                       }} className="FimboAddPage__form__item_input"/>
            </div>
            <div className="hint__description__field">Ссылка, на которую переходит пользователь, нажав кнопку "Купить" или нажав на корзину.</div>

            <FimboAddPurchase wasChanges={wasChanges}/>
            <FimboAddAutotextDesign wasChanges={wasChanges}/>
            <FimboAddAutotextSound wasChanges={wasChanges}/>
        </>
    );
})

export default FimboAddInfoLeft;