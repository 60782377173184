export const baseUrl = "https://admin.fimbo.ru"

const apiPrefix = "/api"
const apiURL = baseUrl + apiPrefix

export const authURL = `${apiURL}/user/auth`
export const loginURL = `${apiURL}/user/login`
export const hostURL = `${apiURL}/variable/host`
export const lessonURL = `${apiURL}/lesson`
export const excelURL = `${apiURL}/analytics/excel-file`

export const fimboUrlPref = apiPrefix + "/fimbo"
export const lessonUrlPref = apiPrefix + "/lesson"
export const lessonTypeUrlPref = apiPrefix + "/lesson-type"
export const fimboLessonLinkAddUrlPref = apiPrefix + "/fimbo-lesson-link/add"
export const fimboLessonLinkChangeUrlPref = apiPrefix + "/fimbo-lesson-link/change"
export const genreUrlPref = apiPrefix + "/genre"
export const genreLessonLinkAddUrlPref = apiPrefix + "/genre-lesson-link/add"
export const genreLessonLinkChangeUrlPref = apiPrefix + "/genre-lesson-link/change"
export const variableUrlPref = apiPrefix + "/variable"
export const audioUrlPref = apiPrefix + "/audio"
export const soulUrlPref = apiPrefix + "/soul"
export const recoverUrlPref = apiPrefix + "/recover"
export const soulFimboLinkAddUrlPref = apiPrefix + "/soul-fimbo-link/add"
export const soulFimboLinkChangeUrlPref = apiPrefix + "/soul-fimbo-link/change"
export const analyticFimboLikesUrlPref = apiPrefix + "/analytics/fimbo"
export const analyticSoulLikesUrlPref = apiPrefix + "/analytics/soul"
export const analyticLessonLikesUrlPref = apiPrefix + "/analytics/lesson"

