import axios from 'axios';
import React from "react";
import {excelURL} from "../../../http/urls";
import {getCookie} from "../../../http/cookies";

const DownloadExcelButton = () => {
    const downloadExcel = async (): Promise<void> => {
        try {
            const response = await axios.get<Blob>(excelURL, {
                responseType: 'blob',
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'analytics.xlsx');
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }
    };

    return (
        <div className="Analytic__download" onClick={downloadExcel}>Скачать аналитику</div>
    );
};

export default DownloadExcelButton;