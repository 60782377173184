import React, {useRef, useState} from 'react';
import "./RecoverPage.scss"
import {RecoverApi} from "../../store/services/RecoverApiService";
import FailedFetchMessage from "../../components/Messages/FailedFetchMessage";
import Loading from "../../components/Loading/Loading";
import RecoverItemRow from "./components/RecoverItemRow";
import RecoverEmptyRow from "./components/RecoverEmptyRow";

const RecoverPage = () => {
    const types = useRef(['lesson', 'fimbo', 'audio', 'lesson-type', 'variable', 'genre', 'soul'])
    const [type, setType] = useState(types.current[0])
    const {data, isLoading, isError} = RecoverApi.useFetchQuery(type)

    return (
        <>
            <div className="RecoverPage__types">
                Типы:
                {types.current.map(ent =>
                    <div key={ent} onClick={() => setType(ent)} className={ent === type ? "RecoverPage__types__button RecoverPage__types__button__selected" : "RecoverPage__types__button"}>
                        {ent}
                    </div>
                )}
            </div>
            {isError &&
                <FailedFetchMessage/>
            }

            {isLoading ?
                <Loading/>
                :
                <>
                    {(data) &&
                        <>
                            <RecoverEmptyRow/>
                            {data.map((ent, index) =>
                            <RecoverItemRow type={type} entity={ent} index={index} key={ent.id}/>
                            )}
                        </>

                    }
                </>
            }

        </>
    );
};

export default RecoverPage;