import React from 'react';

const RecoverEmptyRow = () => {
    return (
        <div className="RecoverItemRow__row RecoverItemRow__row_empty">
            <div className="RecoverItemRow__row__id">
                id
            </div>

            <div className="RecoverItemRow__row__name">
                Название
            </div>

            <div className="RecoverItemRow__button__size"></div>
        </div>
    );
};

export default RecoverEmptyRow;