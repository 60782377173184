import React, {FC, memo} from 'react';
import {IFimboAdd} from "../../FimboAddPage";

const FimboAddInfoRight: FC<IFimboAdd> = memo(({wasChanges}) => {

    const numbers27 = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]


    return (
        <>
            {numbers27.map(note =>
                <div key={note}>
                    <div className="FimboAddPage__form__item">
                        <div className="FimboAddPage__form__item_text">22см нота {note}</div>
                        <input autoComplete={"off"} required name={"fimnote22cm_" + note}
                               onChange={() => {
                                   wasChanges.current = true
                               }}
                               className="FimboAddPage__form__item_input"/>
                    </div>
                    <div className="hint__description__field">Название аудио файла ноты №{note} для 22см Фимбо.
                        (Например note_22_{note}.mp4)
                    </div>
                </div>
            )}
            {numbers.map(note =>
                <div key={note}>
                    <div className="FimboAddPage__form__item">
                        <div className="FimboAddPage__form__item_text">27см нота {note}</div>
                        <input autoComplete={"off"} required name={"fimnote27cm_" + note}
                               onChange={() => {
                                   wasChanges.current = true
                               }}
                               className="FimboAddPage__form__item_input"/>
                    </div>
                    <div className="hint__description__field">Название аудио файла ноты №{note} для 27см Фимбо.
                        (Например note_27_{note}.mp4)
                    </div>
                </div>
            )}
            {
                numbers.map(note =>
                    <div key={note}>
                        <div className="FimboAddPage__form__item">
                            <div className="FimboAddPage__form__item_text">32см нота {note}</div>
                            <input autoComplete={"off"} required name={"fimnote32cm_" + note}
                                   onChange={() => {
                                       wasChanges.current = true
                                   }}
                                   className="FimboAddPage__form__item_input"/>
                        </div>
                        <div className="hint__description__field">Название аудио файла ноты №{note} для 32см Фимбо.
                            (Например note_32_{note}.mp4)
                        </div>
                    </div>
                )}


            {numbers.map(note =>
                <div key={note}>
                    <div className="FimboAddPage__form__lepest__item">
                        <div className="FimboAddPage__form__item_text">Лепесток {note}</div>
                        <div className="FimboAddPage__form__lepest__item__row">
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>rotate</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_transform"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>width</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_width"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>height</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_height"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>left</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_left"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>top</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_top"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>num top</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_num_top"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                            <div className="FimboAddPage__form__lepest__item__row__item">
                                <div>num left</div>
                                <input autoComplete={"off"} required name={"lep" + note + "_num_left"}
                                       onChange={() => {
                                           wasChanges.current = true
                                       }} pattern="[0-9]+.?[0-9]*"
                                       className="FimboAddPage__form__lepest__input"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="hint__description__field">Rotate - Количество градусов, на которые повернут лепесток,
                находящийся на странице плеера.
            </div>
            <div className="hint__description__field">Width - значение ширины лепестка(в процентах, знак процента
                ставить не нужно).
            </div>
            <div className="hint__description__field">Height - значение высоты лепестка(в процентах, знак процента
                ставить не нужно).
            </div>
            <div className="hint__description__field">Left - значение, на котором лепесток находится от левого края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Top - значение, на котором лепесток находится от верхнего края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Num top - значение, на котором цифра находится от левого края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Num left - значение, на котором цифра находится от верхнего края
                контейнера(в процентах, знак процента ставить не нужно).
            </div>
            <div className="hint__description__field">Для того, чтобы подобрать эти значения, нужно перейти на страницу плеера, открыть этот Фимбо и подбивать значения стилей лепестка и цифры в режиме разработчика (rotate, left, right, width, height - для лепестка; num top, num left - для цифры.
            </div>

        </>
    );
})

export default FimboAddInfoRight;