import React, {FC} from 'react';
import {SoulAnswerAnalytic} from "../../../../models/Models";

interface IAnalyticSoulItemBlock{
    soulLike: SoulAnswerAnalytic
    index: number
}

const AnalyticSoulItemBlock : FC <IAnalyticSoulItemBlock> = ({soulLike, index}) => {
    return (
        <div className="SoulAnswer__item__row" style={index % 2 === 0 ? {background: "rgb(238, 246, 255)"} : {}}>
            <div className="SoulAnswer__item__name">{soulLike.text}</div>
            <div className="SoulAnswer__item__stat">{soulLike.total_chosen_state}</div>
            <div className="SoulAnswer__item__stat">{soulLike.total_chosen_history}</div>
        </div>
    );
};

export default AnalyticSoulItemBlock;