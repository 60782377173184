import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    analyticFimboLikesUrlPref,
    analyticLessonLikesUrlPref,
    analyticSoulLikesUrlPref,
    baseUrl
} from "../../http/urls";
import {getCookie} from "../../http/cookies";
import {IStatFimboResponse, IStatLessonResponse, IStatSoulResponse} from "../../models/StoreModels";

export const AnalyticApi = createApi({
    reducerPath: 'analytic',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl
    }),
    endpoints: (build) => ({
        fetchFimboLikes: build.query<IStatFimboResponse, string>({
            query: (params) => ({
                url: `${analyticFimboLikesUrlPref}${params && "?" + params}`,
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                }
            })
        }),
        fetchSoulLikes: build.query<IStatSoulResponse, string>({
            query: (params) => ({
                url: `${analyticSoulLikesUrlPref}${params && "?" + params}`,
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                }
            })
        }),
        fetchLessonLikes: build.query<IStatLessonResponse, string>({
            query: (params) => ({
                url: `${analyticLessonLikesUrlPref}${params && "?" + params}`,
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                }
            })
        }),
    })
})