import React from 'react';
import "./AnalyticPage.scss"
import AnalyticFimboLikesBlock from "./components/analyticFimboBlock/AnalyticFimboLikesBlock";
import AnalyticSoulBlock from "./components/analyticSoulBlock/AnalyticSoulBlock";
import AnalyticLessonLikesBlock from "./components/analyticLessonBlock/AnalyticLessonLikesBlock";
import DownloadExcelButton from "./components/DownloadExcelButton";

const AnalyticPage = () => {
    return (
        <div className="AnalyticPage__container">
            <DownloadExcelButton/>
            <AnalyticFimboLikesBlock/>

            <AnalyticSoulBlock/>

            <AnalyticLessonLikesBlock/>
        </div>
    );
};

export default AnalyticPage;