import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl, recoverUrlPref} from "../../http/urls";
import {IRecoverResponse} from "../../models/StoreModels";
import {getCookie} from "../../http/cookies";

export const RecoverApi = createApi({
    reducerPath: 'recover',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl
    }),
    tagTypes: ['Recover'],
    endpoints: (build) => ({
        fetch: build.query<IRecoverResponse[], string>({
            query: (type) => ({
                url: `${recoverUrlPref}/${type}`,
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                }
            }),
            providesTags: () => ['Recover']
        }),

        recover: build.mutation<IRecoverResponse, {id: number, type: string}>({
            query: ({id, type}) => ({
                url: `${recoverUrlPref}`,
                method: "PUT",
                body: {id, type},
                headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                },
                invalidatesTags: ['Recover']
            }),
        }),
    })
})