import React from 'react';
import AnalyticFimboItemBlock from "./AnalyticFimboItemBlock";
import "./AnalyticFimbo.scss"
import AnalyticFimboCircleDiagram from "./AnalyticFimboCircleDiagram";
import {AnalyticApi} from "../../../../store/services/AnalyticApiService";
import FailedFetchMessage from "../../../../components/Messages/FailedFetchMessage";
import Loading from "../../../../components/Loading/Loading";
import {useAppSelector} from "../../../../store/hooks/redux";


const AnalyticFimboLikesBlock = ( ) => {

    const {data, isLoading, isError} = AnalyticApi.useFetchFimboLikesQuery("")
    const {host} = useAppSelector(state => state.hostReducer)


    return (
        <div className="AnalyticLikes__container">

            <div className="AnalyticLikes__header">
                Аналитика по Фимбо
            </div>

            {isError &&
                <FailedFetchMessage/>
            }


            {(isLoading) ?
                <Loading/>
                :
                <>
                    {(data) &&
                        <>
                            <div className="FimboLikes__item__row">
                                <div className="FimboLikes__item__name">Название</div>
                                <div className="FimboLikes__item__forImg"></div>
                                <div className="FimboLikes__item__likeCount">Активные лайки</div>
                                <div className="FimboLikes__item__likeCount">Активные дизлайки</div>
                                <div className="FimboLikes__item__likeCount">История лайков</div>
                                <div className="FimboLikes__item__likeCount">История дизлайков</div>

                            </div>
                            {data.fimboLikes.map((ent, index) =>
                                <AnalyticFimboItemBlock key={ent.id} index={index} fimbo={ent} host={host}/>
                            )}
                            <AnalyticFimboCircleDiagram fimboLikes={data.fimboLikes}/>
                        </>
                    }

                </>
            }
        </div>
    );
};

export default AnalyticFimboLikesBlock;