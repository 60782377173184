import React, {FC} from 'react';
import {IRecoverResponse} from "../../../models/StoreModels";
import {RecoverApi} from "../../../store/services/RecoverApiService";
import {useAppDispatch} from "../../../store/hooks/redux";
import {errorSlice, successSlice} from "../../../store/reducer/MessagesSlice";
import Loading from "../../../components/Loading/Loading";

interface IRecoverItemRow{
    entity: IRecoverResponse
    index: number
    type: string
}

const RecoverItemRow : FC<IRecoverItemRow> = ({entity, index, type}) => {
    const [visible, setVisible] = React.useState(true);
    const [recoverEnt, {isLoading}] = RecoverApi.useRecoverMutation()
    const dispatch = useAppDispatch()
    const {successShow} = successSlice.actions
    const {errorShow} = errorSlice.actions

    const recoverFunction = async () => {
        const id = entity.id
        const newJson: {id: number, type: string } = {
            type, id
        }

        recoverEnt(newJson).then(async (result) => {
            const success = result as {data: IRecoverResponse}
            if (success.data) {
                setVisible(false)
                dispatch(successShow( true))
                setTimeout(() => {
                    dispatch(successShow( false))
                }, 5000)
            } else{
                dispatch(errorShow( true))
                setTimeout(() => {
                    dispatch(errorShow( false))
                }, 5000)
            }
        })
    }
    return (
        <>
            {visible &&
                <div
                    className={index % 2 === 1 ? "RecoverItemRow__row" : "RecoverItemRow__row RecoverItemRow__row__color"}>
                    <div className="RecoverItemRow__row__id">
                        {entity.id}
                    </div>
                    {entity.name &&
                        <div className="RecoverItemRow__row__name">
                            {entity.name}
                        </div>
                    }
                    {entity.text &&
                        <div className="RecoverItemRow__row__name">
                            {entity.text}
                        </div>
                    }

                    <div className="RecoverItemRow__button" onClick={recoverFunction}>Восстановить</div>

                    {(isLoading) &&
                        <Loading/>
                    }
                </div>
            }

        </>
    );
};

export default RecoverItemRow;