import React, {FC} from 'react';
import {FimboLikesAnalytic} from "../../../../models/Models";

interface IAnalyticFimboItemBlock{
    fimbo: FimboLikesAnalytic,
    host: string,
    index: number
}

const AnalyticFimboItemBlock : FC<IAnalyticFimboItemBlock>= ({ fimbo, host, index}) => {

    return (
        <div className="FimboLikes__item__row" style={index % 2 === 0 ? {background: "rgb(238, 246, 255)"} : {}}>
            <div className="FimboLikes__item__name">
                {fimbo.name}
            </div>
            <img className="FimboLikes__item__img" src={host + "/files/cover/" + fimbo.path + "/" + fimbo.img} alt=""/>
            <div className="FimboLikes__item__likeCount">
                {fimbo.total_likes_state}
            </div>
            <div className="FimboLikes__item__likeCount">
                {fimbo.total_dislikes_state}
            </div>
            <div className="FimboLikes__item__likeCount">
                {fimbo.total_likes_history}
            </div>
            <div className="FimboLikes__item__likeCount">
                {fimbo.total_dislikes_history}
            </div>
        </div>
    );
};

export default AnalyticFimboItemBlock;