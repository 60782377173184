import React, {FC, useRef} from 'react';
import {Pie, PieChart, Tooltip} from "recharts";
import {SoulAnswerAnalytic} from "../../../../models/Models";

const AnalyticSoulCircleDiagram : FC<{soulLikes: SoulAnswerAnalytic[]}> = ({soulLikes}) => {
    const dataActiveLikes = useRef(soulLikes.map((ent) =>
        ({name: ent.text, value: Number(ent.total_chosen_state)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const dataActiveDislikes = useRef(soulLikes.map((ent) =>
        ({name: ent.text, value: Number(ent.total_chosen_history)})
    ).sort((ent1, ent2) => ent1.value - ent2.value))

    const width = useRef(400)
    const height = useRef(400)


    return (
        <div className="SoulAnswer__diagram__mainContainer">
            <div className="SoulAnswer__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataActiveLikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                Активные ответы
            </div>

            <div className="SoulAnswer__diagram__container">
                <PieChart width={width.current} height={height.current}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={dataActiveDislikes.current}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label


                    />
                    <Tooltip/>
                </PieChart>
                История ответов
            </div>


        </div>

    );
};

export default AnalyticSoulCircleDiagram;