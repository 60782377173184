import React from 'react';
import {AnalyticApi} from "../../../../store/services/AnalyticApiService";
import FailedFetchMessage from "../../../../components/Messages/FailedFetchMessage";
import Loading from "../../../../components/Loading/Loading";
import {useSearchParams} from "react-router-dom";
import "./AnalyticLesson.scss"
import AmountPages from "../../../../components/AmountPages/AmountPages";
import AnalyticLessonItemBlock from "./AnalyticLessonItemBlock";
import AnalyticLessonFind from "./AnalyticLessonFind";


const AnalyticLessonLikesBlock = () => {
    const [searchParams] = useSearchParams()
    const {data, isLoading, isError} = AnalyticApi.useFetchLessonLikesQuery(searchParams.toString())

    return (
        <div className="AnalyticLikes__container">

            <div className="AnalyticLikes__header">
                Аналитика по Урокам
            </div>

            {isError &&
                <FailedFetchMessage/>
            }
            {(isLoading) ?
                <Loading/>
                :
                <>
                    {(data) &&
                        <>
                            <AnalyticLessonFind/>
                            <div className="LessonLikes__item__row">
                                <div className="LessonLikes__item__name">Название</div>
                                <div className="LessonLikes__item__likeCount">Активные лайки</div>
                                <div className="LessonLikes__item__likeCount">Активные дизлайки</div>
                                <div className="LessonLikes__item__likeCount">История лайков</div>
                                <div className="LessonLikes__item__likeCount">История дизлайков</div>

                            </div>
                            {data.lessonLikes.rows.map((ent, index) =>
                                <AnalyticLessonItemBlock key={ent.id} index={index} lesson={ent}/>
                            )}
                            <AmountPages scroll={false} limitItems={32} count={data.lessonLikes.count}/>
                        </>
                    }

                </>
            }
        </div>
    );
};

export default AnalyticLessonLikesBlock;