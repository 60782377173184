import React, {FC} from 'react';
import {LessonLikesAnalytic} from "../../../../models/Models";

interface IAnalyticLessonItemBlock{
    index: number
    lesson: LessonLikesAnalytic
}

const AnalyticLessonItemBlock : FC<IAnalyticLessonItemBlock> = ({lesson, index}) => {
    return (
        <div className="LessonLikes__item__row" style={index % 2 === 0 ? {background: "rgb(238, 246, 255)"} : {}}>
            <div className="LessonLikes__item__name">
                {lesson.name + (lesson.subtitle ? (" - " + lesson.subtitle) : "")}
            </div>
            <div className="LessonLikes__item__likeCount">
                {lesson.total_likes_state}
            </div>
            <div className="LessonLikes__item__likeCount">
                {lesson.total_dislikes_state}
            </div>
            <div className="LessonLikes__item__likeCount">
                {lesson.total_likes_history}
            </div>
            <div className="LessonLikes__item__likeCount">
                {lesson.total_dislikes_history}
            </div>
        </div>
    );
};

export default AnalyticLessonItemBlock;